body {
  margin: 0;
  padding: 0;
  color: white;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./img/bg.jpg);
  background-color: #231d16;
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}


.ant-card {
  text-align: left;
}

.main .logo-container {
  height: calc(100vh - 10rem);
}
.logo-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  font-size: 20vmin;
  height: 2em;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: normal;
}
.logo span {
  --color: #fff;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: 700;
  font-style: italic;
  display: block;
  position: absolute;
  color: var(--color);
  letter-spacing: -0.005em;
}
.logo span::before,
.logo span::after {
  content: attr(data-text);
  display: block;
  position: relative;
  padding: 0 0.1em;
  z-index: 1;
}
.logo-sign::before,
.logo span::before {
  position: absolute;
  -webkit-text-stroke: 0.1em rgba(0, 0, 0, 0.9);
  z-index: 0;
}
.logo span:first-child {
  transform: translate(-0.255em, -0.25em);
}
.logo span:last-child {
  --color: #ddd;
  transform: translate(0.255em, 0.25em);
}
.logo-bg {
  background-image: radial-gradient(
    rgb(36, 29, 22) 30%,
    rgba(35, 27, 22, 0) 70%
  );
  background-size: contain;
}
.logo-sign {
  order: 3;
  text-align: center;
  display: block;
  color: #fff;
  font-size: clamp(1rem, 6vw, 6rem);
  margin-top: 8.5rem;
  padding-left: 7rem;
}
.logo-sign::before,
.logo-sign::after {
  position: absolute !important;
  top: 2px;
  right: 0px;
  color: #000;
  z-index: -1 !important;
}
.footer {
  width: 100%;
  max-width: 1000px;
  position: relative;
  bottom: 2vh;
}
.footer-column {
  flex: 25%;
  padding: 10px 20px 20px 20px;
}

.plan-column {
  flex: 20%;
  padding: 10px 20px 20px 20px;
}
.footer-column,
.plan-column {
  transform: translate(0px, 100px);
  opacity: 0;
}
.footer-txt h2 {
  padding: 0.2em;
}
@media screen and (orientation: landscape) {
  body {
    background-image: url(./img/bg_g.jpg);
    background-position-x: right;
  }
  .footer-column {
    flex: 25%;
  }
  .logo-sign {
    font-size: clamp(.7rem,4vw,1.7rem);
    margin-top: 35vh;
  }
  .logo {
    max-width: 60vw;
  }
  .logo-container {
    margin-left: 0;
    justify-content: left;
  }
}
